import './about.css';
import { Profile } from './work';
import profile from '../assets/Front/profile.png';
import Progress from '../components/progress';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Fab from '@mui/material/Fab';
import GitHubIcon from '@mui/icons-material/GitHub';
import ToggleColorMode from '../components/darkmode';
import useLocalStorage from 'use-local-storage';
import {BsMedium} from 'react-icons/bs';
import Expand from '../components/ExpandableContent';
import { IconBrandGitlab } from '@tabler/icons-react';

export function About() {

    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

    return(
<>

        <section className='main' data-theme = {theme}>
            <div className='left'>
                <img src={profile}/>
                <br/><br/>
                <div className="line"/>
                <Profile />
                <div className="line"/>
                <ul>
                    <li><ToggleColorMode /></li>
                    <li><a href='/work'>DESIGN</a></li>
                    <li><a href='/about'><u>ABOUT</u></a></li>
                </ul>
            </div>
            <div className='mid'>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                <div className='intro'>
                    <span>Hello! Tanishq Nakra this side.</span>
                </div>
                <div className='line'/>
                    <div className='info'>
                    <div className='about_info'>
                        <div className='visual'>
                            <h3>I'm a Visual designer.</h3>
                            <span>
                            Passionate about creating visually compelling content.<br/>
                            From dynamic web elements, social media creatives<br/>
                            to 2D/3D motion graphics, product visuals, and montage.<br/>
                            I specialize in bringing ideas to life with creativity and precision.
                            </span>
                        </div>
                        <div className='developer'>
                            <h3>I'm a Developer.</h3>
                            <span>
                            <br/>
                            I enjoy self-hosting services for my projects.<br/>
                            Specialize in containerization and network configuration.<br/>
                            Regularly maintain logs and monitor systems <br/>
                            to ensure a sustainable backbone.
                            </span>
                        </div>
                    </div>
                    <div className='tools'>
                            <span>
                            <h3>-  Design Tools</h3>
                            <span> 
                                <ul>
                                <li>Adobe After effects, Premiere Pro</li>
                                <li>Figma, Adobe photoshop, Adobe Illustrator</li>
                                <li>Blender, Cinema 4D</li>
                                </ul>
                            </span>
                            <h3>- Tech Tools</h3>
                            <section className='head'>
                            <h3>Virtualization & Containers</h3>
                        <ul>
                        <li>Proxmox (for managing VMs)</li>
                        <li>PBS (Proxmox Backup Server)</li>
                        <li>Docker,</li>
                        </ul>
                    <Expand>
                        <h3>Monitoring & Logging</h3>
                        <ul>
                        <li>Uptime Kuma: uptime monitoring</li>
                        <li>Graylog: log management</li>
                        <li>ntfy: notification alerts</li>
                        </ul>

                        <h3>Networking & Infrastructure</h3>
                        <ul>
                        <li>Subnets, VLANs, SMB, FTP, MQTT: protocols and networking</li>
                        <li>Socks Proxy & Reverse Proxy: securing connections</li>
                        </ul>

                        <h3>CI/CD & Automation</h3>
                        <ul>
                        <li>GitLab (self-hosted): CI/CD pipelines</li>
                        <li>Ansible, Bash Scripting: automation across systems</li>
                        </ul>

                        <h3>Operating Systems</h3>
                        <ul>
                        <li>Linux</li>
                        <li>macOS</li>
                        <li>Windows</li>
                        </ul>

                        <h3>Programming Languages</h3>
                        <ul>
                        <li>C++</li>
                        <li>Python</li>
                        </ul>
                    </Expand>
                            </section>
                            </span>
                    </div>
                        </div>
                    <div className='line' />
                    <div className='work'>
                        <div className='experience'>
                            <br/><h2><u>Work Experience</u></h2>

                            <h3>Wishnew Wellness</h3>
                            <p>DevOps Engineer<br/>
                            October 2022 - November 2023
                            </p>

                            <h3>Nectar Infotel</h3>
                            <p>Graphic Designer<br/>
                            Feburary 2021 - August 2022
                            </p>

                        </div>

                        <div className='education'>
                            <br/><h2><u>Education</u></h2>

                            <h3>SRM Institute of Science and Technology</h3>
                            <p>M.S. Computer Science<br/>
                            2024 - 2026
                            </p>

                            <h3>Symbiosis Institute of Computer <br/> Studies and Research, Pune</h3>
                            <p>Bachelor of computer applications<br/>
                            2020 - 2023
                            </p>

                            <h3>Delhi Public School, Faridabad</h3>
                            <p>Class - 12<br/>
                            2006 - 2020
                            </p>

                        </div>
                        <div className='CV'>
                            <br/>
                            <h3>CV</h3>
                            <a href={require('../assets/Front/Resume.pdf')}>
                                <div className='cv_button'>
                                    <Progress />
                                </div>
                            </a>
                        </div>
                </div>
                    <br/><div className='line'/>
                    <div className='contact'>
                        <br/><h2>Get in touch</h2>
                        <a href='mailto: nakratanishq8@gmail.com'>nakratanishq8@gmail.com</a><br/><br/>

                        <Fab variant='circular' size = 'small' href='https://www.linkedin.com/in/tanishq-n-4092971a1/'> 
                        <LinkedInIcon/> 
                        </Fab>
                        <Fab size = 'small' href='https://github.com/Narco-AI'> 
                        <GitHubIcon />
                        </Fab>
                        <Fab size = 'small' href='https://medium.com/@tnakra'>
                            <BsMedium size={25}/>
                        </Fab>
                    </div>
                </div>
        </section>
        </>)
}